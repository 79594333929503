<template>
  <v-row class="mx-0 my-2 mx-md-2">
    <v-col
      cols="12"
      md="6"
    >
      <div class="font-weight-bold">
        {{ $t('settings.crm.credentials.title') }}
      </div>
      <div class="grey--text">
        {{ $t('settings.crm.credentials.flowfact-texts.0') }}
      </div>
    </v-col>
    <v-col
      cols="12"
      offset-md="1"
      md="5"
      class="pt-2 pt-md-8 d-flex flex-column align-end"
    >
      <Credentials
        :disabled="disabled"
        placeholder="1d4ed3a7-3d6f-4f25-f227-c5fa0cc3ab4d"
        :token="token"
        :is-token-verified="isTokenVerified"
        :validation-rules="[rules.required]"
        :save-credentials="saveCredentials"
        :validate-credentials="validateCredentials"
      />
    </v-col>
  </v-row>
</template>

<script>
import VALIDATE_FLOWFACT_CREDENTIALS from './queries/ValidateFlowfactCredentials.gql'
import SAVE_FLOWFACT_CREDENTIALS from './queries/SaveFlowfactCredentials.gql'
import GET_FLOWFACT_CONFIG from './queries/FlowfactConfig.gql'
import { required } from '@/lib/validation'
import Credentials from '../Credentials.vue'

export default {
  components: { Credentials },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isTokenVerified: false,
      token: null
    }
  },
  created () {
    this.rules = { required }
  },
  methods: {
    async saveCredentials (token) {
      await this.$apollo.mutate({
        mutation: SAVE_FLOWFACT_CREDENTIALS,
        variables: { input: { token } },
        update: (store, { data: { flowfactConfig } }) => {
          const data = store.readQuery({ query: GET_FLOWFACT_CONFIG })
          data.flowfactConfig = flowfactConfig
          store.writeQuery({ query: GET_FLOWFACT_CONFIG, data })
        }
      })
    },
    async validateCredentials (token) {
      await this.$apollo.mutate({
        mutation: VALIDATE_FLOWFACT_CREDENTIALS,
        variables: { input: { token } },
        update: (store, { data: { flowfactConfig } }) => {
          const data = store.readQuery({ query: GET_FLOWFACT_CONFIG })
          data.flowfactConfig = flowfactConfig
          this.$emit('isVerified', flowfactConfig.isTokenVerified)
          store.writeQuery({ query: GET_FLOWFACT_CONFIG, data })
        }
      })
    }
  },
  apollo: {
    flowfactConfig: {
      query: GET_FLOWFACT_CONFIG,
      variables () {
        return {
          companyId: this.$auth.user.companyId
        }
      },
      update ({ flowfactConfig }) {
        this.token = flowfactConfig?.token
        this.isTokenVerified = flowfactConfig?.isTokenVerified ?? false
        this.$emit('isVerified', this.isTokenVerified)
        return flowfactConfig
      }
    }
  }
}
</script>

<style scoped>
  .verified >>> .v-text-field__slot input {
    color: var(--primary-color);
  }
  .verified.v-text-field--outlined >>> fieldset {
    border-color: var(--primary-color);
  }
</style>
