<template>
  <v-row class="mx-0 my-2 mx-md-2">
    <v-col
      cols="12"
      md="6"
    >
      <div class="font-weight-bold">
        {{ $t('settings.crm.credentials.title') }}
      </div>
      <div class="grey--text">
        {{ $t('settings.crm.credentials.propstack-texts.0') }}
      </div>
    </v-col>
    <v-col
      cols="12"
      offset-md="1"
      md="5"
      class="pt-2 pt-md-8 d-flex flex-column align-end"
    >
      <Credentials
        :disabled="disabled"
        :hint="$t('settings.crm.credentials.propstack-texts.1')"
        placeholder="SMzzrQiGjJv3TRyWwEGvf8GToUHhsji6nlPw0ppB"
        :token="token"
        :is-token-verified="isTokenVerified"
        :validation-rules="[rules.length]"
        :token-length="40"
        :save-credentials="saveCredentials"
        :validate-credentials="validateCredentials"
      />
    </v-col>
  </v-row>
</template>

<script>
import VALIDATE_PROPSTACK_CREDENTIALS from './queries/ValidatePropstackCredentials.gql'
import SAVE_PROPSTACK_CREDENTIALS from './queries/SavePropstackCredentials.gql'
import GET_PROPSTACK_CONFIG from './queries/PropstackConfig.gql'
import Credentials from '../Credentials.vue'

export default {
  components: { Credentials },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isTokenVerified: false,
      token: null
    }
  },
  created () {
    this.rules = {
      length: (value) => (!value || value?.length === 40) || this.$t('settings.crm.credentials.propstack-texts.2')
    }
  },
  methods: {
    async saveCredentials (token) {
      await this.$apollo.mutate({
        mutation: SAVE_PROPSTACK_CREDENTIALS,
        variables: { input: { token } },
        update: (store, { data: { propstackConfig } }) => {
          const data = store.readQuery({ query: GET_PROPSTACK_CONFIG })
          data.propstackConfig = propstackConfig
          store.writeQuery({ query: GET_PROPSTACK_CONFIG, data })
        }
      })
    },
    async validateCredentials (token) {
      await this.$apollo.mutate({
        mutation: VALIDATE_PROPSTACK_CREDENTIALS,
        variables: { input: { token } },
        update: (store, { data: { propstackConfig } }) => {
          const data = store.readQuery({ query: GET_PROPSTACK_CONFIG })
          data.propstackConfig = propstackConfig
          this.$emit('isVerified', propstackConfig.isTokenVerified)
          store.writeQuery({ query: GET_PROPSTACK_CONFIG, data })
        }
      })
    }
  },
  apollo: {
    propstackConfig: {
      query: GET_PROPSTACK_CONFIG,
      variables () {
        return {
          companyId: this.$auth.user.companyId
        }
      },
      update ({ propstackConfig }) {
        this.token = propstackConfig.token
        this.isTokenVerified = propstackConfig.isTokenVerified
        this.$emit('isVerified', propstackConfig.isTokenVerified)
        return propstackConfig
      }
    }
  }
}
</script>
